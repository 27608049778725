import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Box sx={{
      textAlign: "center"
    }} mdxType="Box">
      <h1 {...{
        "id": "conheça-uma-web-melhor",
        "style": {
          "position": "relative"
        }
      }}>{`Conheça uma Web melhor`}<a parentName="h1" {...{
          "href": "#conhe%C3%A7a-uma-web-melhor",
          "aria-label": "conheça uma web melhor permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h1>
      <p><img parentName="p" {...{
          "src": "/images/kernel_process.png",
          "alt": "Learn"
        }}></img></p>
    </Box>
    <h2 {...{
      "id": "-jornada-de-aprendizagem",
      "style": {
        "position": "relative"
      }
    }}>{`📖 Jornada de Aprendizagem`}<a parentName="h2" {...{
        "href": "#-jornada-de-aprendizagem",
        "aria-label": " jornada de aprendizagem permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "a-jornada-de-8-etapas",
      "style": {
        "position": "relative"
      }
    }}>{`A jornada de 8 etapas`}<a parentName="h3" {...{
        "href": "#a-jornada-de-8-etapas",
        "aria-label": "a jornada de 8 etapas permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Títulos`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Explorações Pessoais`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Explorações na Web 3`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌠 `}<a parentName="td" {...{
              "href": "/learn/module-0"
            }}>{`Uma introdução ao Kernel`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🌈 `}<a parentName="td" {...{
              "href": "/learn/module-0/play-of-pattern"
            }}>{`O Papel dos Padrões`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✍️ `}<a parentName="td" {...{
              "href": "/learn/module-0/trust"
            }}>{`Confiança`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌍 `}<a parentName="td" {...{
              "href": "/learn/module-1"
            }}>{`História e Status atual do Ethereum`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🤯 `}<a parentName="td" {...{
              "href": "/learn/module-1/meaning"
            }}>{`Significado`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💯 `}<a parentName="td" {...{
              "href": "/learn/module-1/value"
            }}>{`Valor`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`💰 `}<a parentName="td" {...{
              "href": "/learn/module-2"
            }}>{`Um sistema Financeiro Global`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`⁉️ `}<a parentName="td" {...{
              "href": "/learn/module-2/better-questions"
            }}>{`Boas Perguntas`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💸 `}<a parentName="td" {...{
              "href": "/learn/module-2/money-speech"
            }}>{`Money and Speech`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌐 `}<a parentName="td" {...{
              "href": "/learn/module-3"
            }}>{`Reconquistando a Web`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🔍 `}<a parentName="td" {...{
              "href": "/learn/module-3/intention"
            }}>{`Intenção`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🦄 `}<a parentName="td" {...{
              "href": "/learn/module-3/freedom"
            }}>{`Liberdade`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ℹ️ `}<a parentName="td" {...{
              "href": "/learn/module-4"
            }}>{`Idade das Instituições na WEb`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👑 `}<a parentName="td" {...{
              "href": "/learn/module-4/governance"
            }}>{`Governe a si mesmo`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`➕ `}<a parentName="td" {...{
              "href": "/learn/module-4/liberal-radical"
            }}>{`Liberdade Radical`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`📈 `}<a parentName="td" {...{
              "href": "/learn/module-5"
            }}>{`Tokens e Desenho de Mecanismos`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👂 `}<a parentName="td" {...{
              "href": "/learn/module-5/listening-stories"
            }}>{`Histórias e Escuta`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👨‍👩‍👧‍👦 `}<a parentName="td" {...{
              "href": "/learn/module-5/incentives"
            }}>{`Incentivos`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`⚖️ `}<a parentName="td" {...{
              "href": "/learn/module-6"
            }}>{`Escalando Jogos de Princípios`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🎓 `}<a parentName="td" {...{
              "href": "/learn/module-6/learn"
            }}>{`Aprenda como Aprender`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`👮 `}<a parentName="td" {...{
              "href": "/learn/module-6/censorship-resistance"
            }}>{`Resitir á Censura`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🎁 `}<a parentName="td" {...{
              "href": "/learn/module-7"
            }}>{`O Presente`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`💝 `}<a parentName="td" {...{
              "href": "/learn/module-7/giving"
            }}>{`Doar`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`🏊 `}<a parentName="td" {...{
              "href": "/learn/module-7/scale-ability"
            }}>{`EscalABILIDADE`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "estrutura-interna",
      "style": {
        "position": "relative"
      }
    }}>{`Estrutura Interna`}<a parentName="h3" {...{
        "href": "#estrutura-interna",
        "aria-label": "estrutura interna permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Leitura Indicada`}</strong>{`  é formada por duas sessões separadas: uma sequência de perguntas “pessoais” pensadas para te ensinar algum princípio que acreditamos ser importante para sua jornada; e uma sessão de perguntas mais técnicas que tratam do cerne de questões mais críticas, essas que normalmente nós não paramos para refletir sobre, como “Confiança”, “Valor” e “Liberdade”. `}</li>
      <li parentName="ol">{`A `}<strong parentName="li">{`Leitura Indicada`}</strong>{` não é longa e é, esperamos, interessante. Para cada parte prevemos em torno de 5 minutos de leitura, então esperamos pelo menos 10 minutos de dedicação antes do início de cada semana. As ideias presentes em cada parte não representam a “verdade” - o objetivo delas é te fazer pensar e chegar às suas próprias conclusões em relação a uma vasta gama de conceitos.`}</li>
      <li parentName="ol"><strong parentName="li">{`Curadoria de Conteúdo`}</strong>{` realmente `}<strong parentName="li">{`NÃO`}</strong>{` é uma grande lista de links que ninguém vai ter tempo de ler e refletir sobre. `}</li>
      <li parentName="ol">{`Nós escolhemos 3-4 conteúdos  para cada semana, em diferentes formatos (vídeo, texto, áudio) e escrevemos instruções detalhadas para cada um dos itens. Isso porque a gente não quer te encher de informação; nós queremos te ajudar a encontrar as melhores ideias e usar isso para transformar as suas habilidades para que então você possa pensar e criar sistemas e ferramentas descentralizadas. `}</li>
    </ol>
    <h3 {...{
      "id": "agenda",
      "style": {
        "position": "relative"
      }
    }}>{`Agenda`}<a parentName="h3" {...{
        "href": "#agenda",
        "aria-label": "agenda permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol">{`Nossas semanas começam às `}<strong parentName="li">{`terças-feiras`}</strong>{`, porque parte do que estamos fazendo aqui é quebrar como estamos culturalmente condicionados a pensar. O que é mais alienado do que dias da semana? Se desapegue desse calendário! 😉`}</li>
      <li parentName="ol">{`Nossos `}<strong parentName="li">{`Papos de Fogueira`}</strong>{` será quando traremos os palestrantes mais quentes do mercado e te apresentaremos as mentes mais brilhantes que conhecemos. Esses acontecem às quintas-feiras.`}</li>
      <li parentName="ol">{`Os Papos de Fogueira endereçam especificamente os temas da semana. Eles serão estruturados e transmitidos via uma live no YouTube com o chat liberado para perguntas, caso sobre tempo. `}</li>
      <li parentName="ol">{`Você perceberá neste módulo que conversas e trocas são o meio pelo qual se poderá aprender o que  estamos ensinando aqui. Ao longo de cada semana nós teremos pequenas conversas sobre uma variedade de temas. Essas conversas acontecerão entre você e os membros do seu grupo, porque se aprende melhor através de trocas com nossos colegas.`}</li>
    </ol>
    <Aligner center mdxType="Aligner">
    <Button to='/learn/module-0/' mdxType="Button">Começar!</Button>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      